<template>
  <div class="h-full">
    <div class="drawer-header d-flex align-center mb-4">
      <span class="font-weight-semibold text-base text--primary">{{ eventLocal.id ? 'Update' : 'Add' }} Event</span>
      <v-spacer></v-spacer>
      <v-btn
        v-if="eventLocal.id"
        icon
        small
        class="me-1"
        @click=" $emit('remove-event', eventLocal); $emit('close-drawer')"
      >
        <v-icon size="22">
          {{ icons.mdiTrashCanOutline }}
        </v-icon>
      </v-btn>
      <v-btn
        icon
        small
        @click="$emit('close-drawer')"
      >
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-calendar-event-handler"
    >
      <v-form
        ref="refCalendarEventHandlerForm"
        class="px-5 pt-3 pb-10"
        @submit.prevent="handleFormSubmit"
      >
        <v-text-field
          v-model="eventLocal.title"
          label="Title"
          placeholder="Title"
          outlined
          :rules="[validators.stringLengthValidation(null,300)]"
          hide-details="auto"
          dense
          class="mb-6"
        ></v-text-field>

        <v-select
          v-model="eventLocal.type"
          outlined
          label="Type"
          placeholder="Type"
          :items="['project','proxy','custom']"
          :rules="[validators.required]"
          hide-details="auto"
          dense
          class="mb-6"
        >
        </v-select>
        <!-- <v-text-field
          v-if="eventLocal.type==='Custom'"
          v-model="eventLocal.custom"
          label="Title"
          placeholder="Title"
          outlined
          :rules="[validators.required]"
          hide-details="auto"
          dense
          class="mb-6"
        ></v-text-field> -->
        <DatePicker
          v-model="eventLocal.date"
          :is-calendar="true"
        />
        <v-textarea
          v-model="eventLocal.description"
          outlined
          label="Description"
          placeholder="Description"
        ></v-textarea>

        <v-btn
          color="primary"
          class="me-3"
          type="submit"
        >
          {{ eventLocal.id ? 'Update' : 'Add ' }}
        </v-btn>
        <v-btn
          outlined
          @click="resetEventLocal"
        >
          Reset
        </v-btn>
      </v-form>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { required, urlValidator, stringLengthValidation } from '@core/utils/validation'
import { dateInPast } from '@core/utils'
import { mdiTrashCanOutline, mdiClose } from '@mdi/js'
import DatePicker from '../DatePicker.vue'

export default {
  components: {
    PerfectScrollbar, DatePicker,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  setup(props, { emit }) {
    // ————————————————————————————————————
    //* ——— Template Refs
    // ————————————————————————————————————

    const refCalendarEventHandlerForm = ref(null)

    // ————————————————————————————————————
    //* ——— Local Event
    // ————————————————————————————————————

    const eventLocal = ref(JSON.parse(JSON.stringify(props.event)))
    const resetEventLocal = () => {
      eventLocal.value = JSON.parse(JSON.stringify(props.event))

      // Only get date from event
      // if (eventLocal.value.start) eventLocal.value.start = new Date(eventLocal.value.start).toISOString().substr(0, 10)
      // if (eventLocal.value.end) eventLocal.value.end = new Date(eventLocal.value.end).toISOString().substr(0, 10)

      // Reset Validation
      refCalendarEventHandlerForm.value.resetValidation()
    }
    watch(
      () => props.event,
      () => {
        resetEventLocal()
      },
      { deep: true },
    )

    // ————————————————————————————————————
    //* ——— Form
    // ————————————————————————————————————

    const isStartDateMenuOpen = ref(false)
    const isEndDateMenuOpen = ref(false)
    const calendarOptions = ref([])

    const handleFormSubmit = () => {
      const isFormValid = refCalendarEventHandlerForm.value.validate()

      if (!isFormValid) return

      const eventData = JSON.parse(JSON.stringify(eventLocal.value))

      // * If event has id => Edit Event
      // Emit event for add/update event
      if (eventData.id) emit('update-event', eventData)
      else emit('add-event', eventData)

      // Close sidebar
      emit('close-drawer')
    }

    // ————————————————————————————————————
    //* ——— Perfect Scrollbar
    // ————————————————————————————————————

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      wheelSpeed: 0.7,
    }

    // ————————————————————————————————————
    //* ——— Form Validator
    // ————————————————————————————————————

    const endDateValidator = val => {
      if (!eventLocal.value.start) return true

      return !dateInPast(new Date(val), new Date(eventLocal.value.start)) || 'End date is in past'
    }

    return {
      // Template Refs
      refCalendarEventHandlerForm,

      // Local Event
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // Form
      isStartDateMenuOpen,
      isEndDateMenuOpen,
      handleFormSubmit,

      // Perfect Scrollbar
      perfectScrollbarSettings,

      // Field Validators
      validators: {
        required,
        urlValidator,
        endDateValidator,
        stringLengthValidation,
      },

      // Icons
      icons: {
        mdiTrashCanOutline,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
.ps-calendar-event-handler {
  height: calc(100% - 44px - 24px - 2px);

  // Fixes: if chips row >2 => Create enough space between chips row & avoid first row chip touching the label
  // Related Issue: https://github.com/vuetifyjs/vuetify/issues/13107
  .select-guest {
    .v-select__selections {
      .v-chip--select {
        margin-bottom: 6px;
        margin-top: 6px;
      }
    }
  }
}
</style>
