import CalendarApi from '@core/api/calendar'

export const CALENDAR_MODULE_NAME = 'app-calendar'

export default {
  namespaced: true,
  state: {
    events: [],
  },
  getters: {},
  mutations: {
    SET_EVENTS(state, events) {
      state.events = events
    },
    EDIT_PROXY(state, value) {
      const editedEventIdx = state.events.findIndex(p => p.id === value.id)

      if (~editedEventIdx) {
        state.proxies.splice(editedEventIdx, 1, value)

        return value
      }

      return null
    },

    REMOVE_PROXY(state, event) {
      const deletedEventIdx = state.events.findIndex(p => p.id === event.id)

      //
      if (~deletedEventIdx) {
        return state.proxies.splice(deletedEventIdx, 1)
      }

      return null
    },
  },
  actions: {
    fetchEvents({ commit }, params) {
      return CalendarApi.fetchEvents(params)
        .then(response => {
          commit('SET_EVENTS', response.data.items)

          return response
        })
    },
    addEvent(ctx, { event }) {
      return CalendarApi.addEvent({ event })
    },
    updateEvent(ctx, { event }) {
      return CalendarApi.updateEvent({ event })
    },
    removeEvent(ctx, event) {
      return CalendarApi.removeEvent(event)
    },
  },
}
