<template>
  <v-card class="max-h-content-container app-calendar position-relative overflow-hidden text-sm">
    <v-navigation-drawer
      v-model="isLeftSidebarOpen"
      width="250"
      mobile-breakpoint="sm"
      absolute
      touchless
      :right="$vuetify.rtl"
      :temporary="$vuetify.breakpoint.xsOnly"
      style="z-index: 2"
    >
      <calendar-left-sidebar-content
        :selected-types.sync="selectedTypes"
        :events-types="eventsTypes"
        :color-map="eventsColorMap"
        @open-evnt-handler-drawer="isEventHandlerDrawerActive = true"
      >
      </calendar-left-sidebar-content>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="isEventHandlerDrawerActive"
      :right="!$vuetify.rtl"
      touchless
      app
      temporary
      width="420"
    >
      <calendar-event-handler-drawer-content
        :event="event"
        :clear-event-data="clearEventData"
        @remove-event="tryRemoveEvent"
        @add-event="tryAddEvent"
        @update-event="tryUpdateEvent"
        @close-drawer="isEventHandlerDrawerActive = false"
      ></calendar-event-handler-drawer-content>
    </v-navigation-drawer>

    <div
      :style="contentStyles"
      class="h-full"
    >
      <div
        v-if="refCalendar"
        class="calendar-header mx-6 my-5 d-flex align-center flex-wrap"
      >
        <v-btn
          icon
          class="d-inline-block d-md-none me-1"
          @click="isLeftSidebarOpen = true"
        >
          <v-icon size="30">
            {{ icons.mdiMenu }}
          </v-icon>
        </v-btn>
        <v-btn
          icon
          class="me-1"
          @click="refCalendar.prev()"
        >
          <v-icon size="30">
            {{ icons.mdiChevronLeft }}
          </v-icon>
        </v-btn>
        <v-btn
          icon
          class="me-3"
          @click="refCalendar.next()"
        >
          <v-icon size="30">
            {{ icons.mdiChevronRight }}
          </v-icon>
        </v-btn>
        <p class="font-weight-semibold text-xl text--primary mb-0 me-6">
          {{ refCalendar.title }}
        </p>

        <v-spacer></v-spacer>

        <v-btn-toggle
          v-model="activeCalendarView"
          color="primary"
          class="mt-3 mt-sm-0"
        >
          <v-btn
            v-for="calendarViewOption in calendarViewOptions"
            :key="calendarViewOption"
            elevation="0"
            :value="calendarViewOption"
          >
            {{ calendarViewOption }}
          </v-btn>
        </v-btn-toggle>
      </div>

      <v-calendar
        ref="refCalendar"
        v-model="calendarValue"
        :type="activeCalendarView"
        :events="tableEvents"
        :event-margin-bottom="5"
        :event-text-color="getEventTextColor"
        locale="en"
        @change="getEvents"
        @click:event="calenderHandleEventClick"
        @click:day="calendarHandleDayClick"
        @click:time="calendarHandleDayClick"
        @click:more="calendarHandleMoreClick"
      ></v-calendar>
    </div>
  </v-card>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { ref, watch } from '@vue/composition-api'
import { mdiChevronLeft, mdiChevronRight, mdiMenu } from '@mdi/js'
import { useResponsiveLeftSidebar } from '@core/comp-functions/ui'
import { mapActions, mapState } from 'vuex'
import { hexToRgb } from '@core/utils'
import store from '@/store'
import calendarStoreModule, { CALENDAR_MODULE_NAME } from '@/store/modules/calendarStoreModule'

// Local Components
import CalendarLeftSidebarContent from '@/components/calendar/CalendarLeftSidebarContent.vue'
import CalendarEventHandlerDrawerContent from '@/components/calendar/CalendarEventHandlerDrawerContent.vue'

export default {
  components: {
    CalendarLeftSidebarContent,
    CalendarEventHandlerDrawerContent,
  },
  data() {
    return {
      eventsColorMap: {
        project: '#16b1ff',
        proxy: '#ffb400',
      },
      selectedTypes: [],
      date_from: '',
      date_to: '',
    }
  },
  computed: {
    ...mapState({
      events(state) {
        return state[CALENDAR_MODULE_NAME]?.events
      },
    }),
    tableEvents() {
      return this.events.filter(el => this.selectedTypes.includes(el.type)).map(el => {
        const rgbColor = hexToRgb(this.eventsColorMap[el.type] || '#9155fd')

        return {
          id: el.id,
          name: el.title || '',
          start: el.date.replace('T', ' ').slice(0, el.date.length - 9),
          end: el.date.replace('T', ' ').slice(0, el.date.length - 9),
          color: `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b}, 0.2)`,
          eventTextColor: this.eventsColorMap[el.type] || '#9155fd',
          data: el,
        }
      })
    },
    eventsTypes() {
      const types = []

      this.events.forEach(el => {
        if (!types.includes(el.type)) {
          types.push(el.type)
        }
      })

      return types
    },
  },
  async mounted() {
    await this.fetchEvents({ date_from: this.date_from, date_to: this.date_to })
    this.selectedTypes = this.eventsTypes.slice()
  },

  methods: {
    ...mapActions({
      fetchEvents(dispatch, payload) {
        return dispatch(`${CALENDAR_MODULE_NAME}/fetchEvents`, payload)
      },
      addEvent(dispatch, payload) {
        return dispatch(`${CALENDAR_MODULE_NAME}/addEvent`, payload)
      },
      updateEvent(dispatch, payload) {
        return dispatch(`${CALENDAR_MODULE_NAME}/updateEvent`, payload)
      },
      removeEvent(dispatch, payload) {
        return dispatch(`${CALENDAR_MODULE_NAME}/removeEvent`, payload)
      },
    }),
    async tryAddEvent(event) {
      await this.addEvent({ event })
      await this.fetchEvents({ date_from: this.date_from, date_to: this.date_to })
    },
    async tryUpdateEvent(event) {
      await this.updateEvent({ event })
      await this.fetchEvents({ date_from: this.date_from, date_to: this.date_to })
    },
    async tryRemoveEvent(event) {
      await this.removeEvent({ event })
      await this.fetchEvents({ date_from: this.date_from, date_to: this.date_to })
    },
    async getEvents(event) {
      this.date_from = event.start.date
      this.date_to = event.end.date
      await this.fetchEvents({ date_from: this.date_from, date_to: this.date_to })
    },
  },
  setup() {
    const { isLeftSidebarOpen, contentStyles } = useResponsiveLeftSidebar({ sidebarWidth: 250 })

    if (!store.hasModule(CALENDAR_MODULE_NAME)) {
      store.registerModule(CALENDAR_MODULE_NAME, calendarStoreModule)
    }

    const refCalendar = ref(null)
    const activeCalendarView = ref('month')
    const calendarViewOptions = ['month', 'week', 'day']
    const calendarValue = ref()
    const calendarEvents = ref([])
    const eventClickBugWorkaround = ref(false)
    const blankEvent = {
      id: null,
      title: '',
      type: '',
      date: '',
      description: '',
    }
    const event = ref(JSON.parse(JSON.stringify(blankEvent)))
    const clearEventData = () => {
      event.value = JSON.parse(JSON.stringify(blankEvent))
    }
    const isEventHandlerDrawerActive = ref(false)

    const calenderHandleEventClick = eventObj => {
      // Grab only event object
      event.value = eventObj.event.data

      // eslint-disable-next-line no-use-before-define
      isEventHandlerDrawerActive.value = true

      // Bug workaround
      eventClickBugWorkaround.value = true
    }

    const calendarHandleDayClick = dayObj => {
      if (eventClickBugWorkaround.value) {
        eventClickBugWorkaround.value = false

        return
      }

      event.value.date = dayObj.date

      // eslint-disable-next-line no-use-before-define
      isEventHandlerDrawerActive.value = true
    }

    const calendarHandleMoreClick = ({ date }) => {
      calendarValue.value = date

      activeCalendarView.value = 'day'

      // Bug workaround
      eventClickBugWorkaround.value = true
    }
    const getEventTextColor = e => e.eventTextColor

    watch(isEventHandlerDrawerActive, val => {
      if (!val) clearEventData()
    })

    return {
      isLeftSidebarOpen,
      contentStyles,

      refCalendar,

      activeCalendarView,
      calendarViewOptions,
      getEventTextColor,
      calendarValue,
      calendarEvents,
      event,
      clearEventData,
      calenderHandleEventClick,
      calendarHandleDayClick,
      calendarHandleMoreClick,

      isEventHandlerDrawerActive,

      // Icons
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
        mdiMenu,
      },
    }
  },
}
</script>

<style>

</style>
