<template>
  <div class="ma-5">
    <v-btn
      block
      color="primary"
      @click="$emit('open-evnt-handler-drawer')"
    >
      Add Event
    </v-btn>

    <!-- Calendars -->
    <p class="text-xs text--disabled mt-8 mb-1">
      CALENDARS
    </p>

    <!-- Calendar Options -->
    <v-checkbox
      v-model="checkAll"
      label="View All"
      hide-details
    ></v-checkbox>
    <v-checkbox
      v-for="item in eventsTypes"
      :key="item"
      v-model="localSelectedTypes"
      :value="item"
      :color="colorMap[item]"
      :label="`${item[0].toUpperCase()}${item.slice(1)}`"
      class="mt-2"
      hide-details
    >
    </v-checkbox>
  </div>
</template>

<script>

export default {
  props: {
    selectedTypes: {
      type: Array,
      default: () => ({}),
    },
    eventsTypes: {
      type: Array,
      default: () => ({}),
    },
    colorMap: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      filterItems: [],
      checkAll: true,
    }
  },
  computed: {
    localSelectedTypes: {
      get() {
        return this.selectedTypes
      },
      set(value) {
        this.$emit('update:selectedTypes', value)
      },
    },
  },
  watch: {
    checkAll() {
      if (!this.checkAll) {
        this.localSelectedTypes = []
      } else {
        this.localSelectedTypes = this.eventsTypes.slice()
      }
    },
  },
}
</script>

<style>
</style>
