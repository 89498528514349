import axios from '@axios'

export default {
  fetchEvents: params => axios
    .get('/calendar', {
      params,
    }),
  addEvent: ({ event }) => axios
    .post('/calendar', event),
  updateEvent: ({ event }) => axios
    .patch(`/calendar/${event.id}`, event),
  removeEvent: event => axios
    .delete(`/calendar/${event.event.id}`),
}
